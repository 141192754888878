.scrollbars::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px
}

.scrollbars::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5)!important;
    border-radius: 4px;
    box-shadow: 0 0 1px rgba(255, 255, 255, .5)!important;
}
